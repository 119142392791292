import { gsap } from "gsap";
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(SplitText);

var timing= 1; // TIMING TO BLOCK THE WHEEL EVENT
var index = 1; // INDEX TO GET THE STEP OF THE SLIDER
var scrollUnlock = 1; // BLOCK THE WHEEL EVENT 
var positionY = 0; // INITIATE THE VERTICAL POSITION  
let width = document.getElementById('main').offsetWidth; // GET THE WIDTH OF THE SCREEN
let height = document.getElementById('main').offsetHeight; // GET THE HEIGHT OF THE SCREEN
var positionX = - parseFloat(width); // INITIATE THE HORIZONTAL POSITION AT THE SECOND STEP
var positionSecondX = 0; // INITIATE THE HORIZONTAL POSITION OF THE SECOND SLIDER
var timelineFirstSlide = gsap.timeline(); // INITIATE THE FIRST ANIMATION
var lineDuration; // INITIATE THE DURATION OF THE FIRST ANIMATION

document.addEventListener("DOMContentLoaded", function(event) { 
    SetUTM()

        var firstSliderContainer = document.getElementById('first-slider'); // FIRST SLIDER
        var secondSliderContainer = document.getElementById('second-slider'); // SECOND SLIDER
        var scrollContainer = [].slice.call(document.getElementsByClassName('slide--second'));  // GET ALL SLIDES OF THE SECOND SLIDER
        var progressBar = document.getElementById("progress-bar"); // GET THE PROGRESS BAR
        var firstTitleSplit = new SplitText("#first-title", { type: "words,chars" }); // INITIATE SPLITTEXT OF THE FIRST TITLE
        var firstChars = firstTitleSplit.chars;
        var secondTitleSplit = new SplitText("#second-title", { type: "words,chars" }); // INITIATE SPLITTEXT OF THE SECOND TITLE
        var secondChars = secondTitleSplit.chars;
        var sizeCta;
        var slideBckgrnd; 
        
        document.getElementById('first-slide').dataset.positionX =  Math.abs(3 * width) * -1 + parseFloat(width); // ASSIGN THE HORIZONTAL POSITION TO INITIATE A LIMITATION AT THE ANIMATION
        
        window.onresize = adaptValueResize; // LAUNCH THE UPDATE OF THE SLIDER COORDONATE AT THE RESIZE
        
        if(width > 767){
            document.getElementById('main__inner').dataset.positionY =  Math.abs(3 * height) * -1 + parseFloat(height); // ASSIGN THE VERTICAL POSITION TO INITIATE A LIMITATION AT THE ANIMATION
            sizeCta = "90%";
            slideBckgrnd = "0% 0";
            
        }else{
            document.getElementById('main__inner').dataset.positionY =  Math.abs(4 * height) * -1 + parseFloat(height); // ASSIGN THE VERTICAL POSITION TO INITIATE A LIMITATION AT THE ANIMATION
            sizeCta = "100%"
            slideBckgrnd = "60% 0";
        }

    // !-----------------------! FIRST ANIMATION !-----------------------!
        

        timelineFirstSlide.from(firstChars,{
            duration:0.1,
            opacity: 0,
            stagger: 0.045,
            
        })
        .to("#first-slide",{
            x: - parseFloat(width),
            duration:1,
        },">2.5")
        .to("#first-slide",{
            backgroundPosition:"50% 0",
            duration:1,
        }, "<")
        .to("#main_cta",{
            width: sizeCta,
            duration:1,
        }, "<")
        .from(secondChars,{
            duration:0.1,
            opacity: 0,
            stagger: 0.045,
            
        })
        .fromTo(".progression",
        {
            opacity: 0,
            display: "none"
        },
        {
            opacity: 1,
            display: "flex",
            duration:1,
        },
         "<");

        lineDuration = timelineFirstSlide.duration();
        progressUpdate(progressBar);

         // GET TO THE END OF THE FIRST ANIMATION AT THE CLICK

        document.getElementById("skip").addEventListener('click', function(evt) {
            evt.preventDefault();
            if(timelineFirstSlide.isActive() === true){
                timelineFirstSlide.seek(lineDuration, false);
            }
        });

        document.querySelector('.progression__text').addEventListener('click', function(e) {
            e.preventDefault();

            var l = document.querySelectorAll('.slide--second')

            if (positionX != document.getElementById('first-slide').dataset.positionX) {
                animationSecondScreen();
            } else if (l[index - 2] != undefined) {
                animationSlideCase(l[index - 2])
            } else {
                if(positionY != document.getElementById('main__inner').dataset.positionY){
                    gsap.to("#main__inner",{
                        duration: 1,
                        y:parseFloat(positionY) - parseFloat(height)
                    });

                    if(index == 7){
                        gsap.to(".progression",{
                            duration: 1,
                            y: - parseFloat(height),
                            opacity:0
                        });
                        gsap.to("#main_cta",{
                            duration:1,
                            y: - parseFloat(height),
                            opacity:0

                        })
                    }

                    positionY = parseFloat(positionY) - parseFloat(height);  
                    index++;
                }
            }

            progressUpdate(progressBar);
        })

// !-----------------------! FIRST CONTAINER ANIMATION AND EVENT !-----------------------!
    // !-----------------------! SCROLL EVENT !-----------------------!


    firstSliderContainer.addEventListener('wheel', function(evt) {
        evt.preventDefault();
        if(timelineFirstSlide.isActive() === false){
            if (scrollUnlock === 1) { // CHECK IF WE CAN SCROLL AGAIN
                if(evt.deltaY > 0){ // SCROLL DOWN
                    if(positionX != document.getElementById('first-slide').dataset.positionX){
                        animationSecondScreen();
                    }else{
                        animationSlideCase(secondSliderContainer.firstElementChild);
                    }
                }else{ // SCROLL UP
                    if(positionX != - parseFloat(width)){
                        gsap.to("#first-slide",{
                            duration: 1,
                            x: - parseFloat(width),
                        })
                        positionX = parseFloat(positionX) + parseFloat(width);
                        index--;
                    }      
                    timing = 1.5;
                }

                scrollUnlock = 0; // BLOCK THE EVENT
                progressUpdate(progressBar);
                // RELAUNCH THE EVENT AFTER TIMING
                setTimeout(function() { 
                    scrollUnlock = 1;
                }, timing*1000);
            }
        }
    });

    // !-----------------------! SWIPE EVENT !-----------------------!


    firstSliderContainer.addEventListener('swiped-left', function(e) {
        if (scrollUnlock === 1) { // CHECK IF WE CAN SCROLL AGAIN
            if(positionX != document.getElementById('first-slide').dataset.positionX){
                animationSecondScreen();
            }else{
                animationSlideCase(secondSliderContainer.firstElementChild);
            }

            scrollUnlock = 0; // BLOCK THE EVENT
            progressUpdate(progressBar);
            // RELAUNCH THE EVENT AFTER TIMING
            setTimeout(function() { 
                scrollUnlock = 1;
            }, timing*1000);
        }
    });

    firstSliderContainer.addEventListener('swiped-right', function(e) {
        console.log("SWIPED R");

        if (scrollUnlock === 1) { // CHECK IF WE CAN SCROLL AGAIN
            if(positionX != - parseFloat(width)){
                gsap.to("#first-slide",{
                    duration: 1,
                    x: - parseFloat(width),
                })
                positionX = parseFloat(positionX) + parseFloat(width);
                index--;
            }    
            timing = 1.5;
            scrollUnlock = 0; // BLOCK THE EVENT
            progressUpdate(progressBar);
            // RELAUNCH THE EVENT AFTER TIMING
            setTimeout(function() { 
                scrollUnlock = 1;
            }, timing*1000);
        }
    });

// !-----------------------! SECOND CONTAINER ANIMATION AND EVENT !-----------------------!
    // !-----------------------! SCROLL EVENT !-----------------------!

          
    scrollContainer.forEach(function(element){
        element.addEventListener('wheel', function(evt) {
            evt.preventDefault();
            if (scrollUnlock === 1) { // CHECK IF WE CAN SCROLL AGAIN
            if(evt.deltaY > 0){  // SCROLL DOWN
                    if(element.nextElementSibling != undefined){ 
                        animationSlideCase(element.nextElementSibling)
                    }else{ 
                        if(positionY != document.getElementById('main__inner').dataset.positionY){
                            gsap.to("#main__inner",{
                                duration: 1,
                                y:parseFloat(positionY) - parseFloat(height)
                            });

                            if(index == 7){
                                gsap.to(".progression",{
                                    duration: 1,
                                    y: - parseFloat(height),
                                    opacity:0
                                });
                                gsap.to("#main_cta",{
                                    duration:1,
                                    y: - parseFloat(height),
                                    opacity:0

                                })
                            }

                            positionY = parseFloat(positionY) - parseFloat(height);  
                            index++;
                        }
                        timing = 1.5;
                    }
                }else{  // SCROLL UP
                    if(element.nextElementSibling != undefined){ 
                        animationCaseBack("right");
                    }else{ 
                        if(positionY != 0){
                            gsap.to("#main__inner",{
                                duration: 1,
                                y:parseFloat(positionY) + parseFloat(height)
                            });
                            if(index == 8){
                                gsap.to(".progression",{
                                    duration: 1,
                                    opacity:1,
                                    y: 0 
                                });
                                gsap.to("#main_cta",{
                                    y: 0,
                                    opacity:1,
                                    duration:1,
                                })
                            }
                            positionY = parseFloat(positionY) + parseFloat(height);  
                            index--;
                        }else{
                            animationCaseBack("right");
                        }
                    }
                    timing = 1.5;
                }
                scrollUnlock = 0; // BLOCK THE EVENT
                progressUpdate(progressBar);
                // RELAUNCH THE EVENT AFTER TIMING
                setTimeout(function() { 
                    scrollUnlock = 1;
                }, timing*1000);
            }
        });

    // !-----------------------! SWIPE EVENT !-----------------------!

        element.addEventListener('swiped-left', function(e) {
            if (scrollUnlock === 1) { // CHECK IF WE CAN SCROLL AGAIN
                if(element.nextElementSibling != undefined){ 
                    animationSlideCase(element.nextElementSibling);
                }else{

                    if(positionY != document.getElementById('main__inner').dataset.positionY){
                        gsap.to("#main__inner",{
                            duration: 1,
                            y:parseFloat(positionY) - parseFloat(height)
                        });
                        if(index == 7){
                            gsap.to(".progression",{
                                duration: 1,
                                    opacity:0,
                                y: - parseFloat(height)
                            });
                            gsap.to("#main_cta",{
                                duration:1,
                                    opacity:0,
                                y: - parseFloat(height)

                            })
                        }
                        positionY = parseFloat(positionY) - parseFloat(height);  
                        index++;
                    }
                    timing = 1.5;
                }
                scrollUnlock = 0; // BLOCK THE EVENT
                progressUpdate(progressBar);
                // RELAUNCH THE EVENT AFTER TIMING
                setTimeout(function() { 
                    scrollUnlock = 1;
                }, timing*1000);
            }
        });

        element.addEventListener('swiped-right', function(e) {
            if (scrollUnlock === 1) { // CHECK IF WE CAN SCROLL AGAIN
                if(element.nextElementSibling != undefined){ 
                    animationCaseBack("right");
                }else{ 
                    if(positionY != 0){
                        gsap.to("#main__inner",{
                            duration: 1,
                            y:parseFloat(positionY) + parseFloat(height)
                        });
                        if(index == 8){
                            gsap.to(".progression",{
                                y: 0, 
                                opacity:1,
                                duration: 1,
                            });
                            gsap.to("#main_cta",{
                                y: 0,
                                opacity:1,
                                duration:1,

                            })
                        }
                        positionY = parseFloat(positionY) + parseFloat(height);  
                        index--;
                    }else{
                        animationCaseBack("right");
                    }
                }
                timing = 1.5;
                scrollUnlock = 0; // BLOCK THE EVENT
                progressUpdate(progressBar);
                // RELAUNCH THE EVENT AFTER TIMING
                setTimeout(function() { 
                    scrollUnlock = 1;
                }, timing*1000);
            }
        });

        element.addEventListener('swiped-up', function(e) {
            if (scrollUnlock === 1) { // CHECK IF WE CAN SCROLL AGAIN
                if(element.nextElementSibling == undefined){  
                    if(positionY != document.getElementById('main__inner').dataset.positionY){
                        gsap.to("#main__inner",{
                            duration: 1,
                            y:parseFloat(positionY) - parseFloat(height)
                        });
    
                        if(index == 7){
                            gsap.to(".progression",{
                                duration: 1,
                                y: - parseFloat(height),
                                opacity:0
                            });
                            gsap.to("#main_cta",{
                                duration:1,
                                y: - parseFloat(height),
                                opacity:0
    
                            })
                        }
    
                        positionY = parseFloat(positionY) - parseFloat(height);  
                        index++;
                    }
                    timing = 1.5;
                }

                scrollUnlock = 0; // BLOCK THE EVENT
                progressUpdate(progressBar);
                // RELAUNCH THE EVENT AFTER TIMING
                setTimeout(function() { 
                    scrollUnlock = 1;
                }, timing*1000);
            }
        });
        element.addEventListener('swiped-down', function(e) {
            if (scrollUnlock === 1) { // CHECK IF WE CAN SCROLL AGAIN
                if(element.nextElementSibling == undefined){  
                    if(positionY != 0){
                        gsap.to("#main__inner",{
                            duration: 1,
                            y:parseFloat(positionY) + parseFloat(height)
                        });
                        if(index == 8){
                            gsap.to(".progression",{
                                y: 0, 
                                opacity:1,
                                duration: 1,

                            });
                            gsap.to("#main_cta",{
                                y: 0,
                                opacity:1,
                                duration:1,
                            })
                        }
                        positionY = parseFloat(positionY) + parseFloat(height);  
                    }else{
                        animationCaseBack("right");
                    }
                    timing = 1.5;
                    index--;
                }

                scrollUnlock = 0; // BLOCK THE EVENT
                progressUpdate(progressBar);
                // RELAUNCH THE EVENT AFTER TIMING
                setTimeout(function() { 
                    scrollUnlock = 1;
                }, timing*1000);
            }
        });
       

    });

}); 


function SetUTM() {
    if (window.location.href.indexOf('utm_') > -1) {
        var list = window.location.href.split('?')[1].split('&')
        var text = []

        for (var y = 0; y < list.length; y++) {
            if (list[y].indexOf('utm_') > -1) {
                text.push(list[y])
            }
        }

        document.querySelectorAll('.js-utm').forEach(v => {
            v.href = v.href + "?" + text.join('&');
        })
    }
}

// !-----------------------! PROGRESSION BAR FUNCTION !-----------------------!


function progressUpdate(progressElement){
    gsap.to(progressElement,{
        duration: .5,
        scaleX: 1/7*index
    });
};

// !-----------------------! RESIZE FUNCTION !-----------------------!

function adaptValueResize() {
    width = document.getElementById('main').offsetWidth;
    height = document.getElementById('main').offsetHeight;
    document.getElementById('first-slide').dataset.positionX =  Math.abs(3 * width) * -1 + parseFloat(width);
    
    if(width > 767){
        document.getElementById('main__inner').dataset.positionY =  Math.abs(3 * height) * -1 + parseFloat(height);
        gsap.to("#main_cta",{
            duration: 0,
            width: "90%",
        })
    }else{
        document.getElementById('main__inner').dataset.positionY =  Math.abs(4 * height) * -1 + parseFloat(height);
        gsap.to("#main_cta",{
            duration: 0,
            width: "100%",
        })
    }      
    if(index < 3){
        positionX = - parseFloat(width) * index;
        gsap.to("#first-slide",{
            duration: 0,
            x: - parseFloat(width) * index,
        })

    }else if(index > 6){
        positionY = - parseFloat(height) * (index-6);
        positionX = - parseFloat(width) * 2;
        positionSecondX = - parseFloat(width) * 4;
        gsap.to("#first-slide",{
            duration: 0,
            x: - parseFloat(width) * 2,
        })
        gsap.to("#second-slider",{
            duration: 0,
            x: - parseFloat(width) * 4,
        })
        gsap.to("#main__inner",{
            duration: 0,
            y: - parseFloat(height) * (index-6),
        })
        console.log(index-6);
    }else{
        positionX = - parseFloat(width) * 2;
        positionSecondX = - parseFloat(width) * (index-2);
        gsap.to("#first-slide",{
            duration: 0,
            x: - parseFloat(width) * 2,
        })
        gsap.to("#second-slider",{
            duration: 0,
            x: - parseFloat(width) * (index-2),
        })
    }

}

// !-----------------------! ANIMATION FUNCTION !-----------------------!

function animationSecondScreen(){
    if(timelineFirstSlide.isActive() === false){
        var thirdTitleSplit = new SplitText("#third-title", { type: "words,chars" });
        var thirdChars = thirdTitleSplit.chars;
        var tl  = gsap.timeline();
        tl.to("#first-slide",{
            duration: 1,
            x: - parseFloat(width) - parseFloat(width / 2)
        })
        .to("#first-slide",{
            duration: 1,
            opacity: 0
        },"<")
        .to("#second-slide",{
            duration: 2,
            backgroundPositionX: '100%'
        })
        .from(thirdChars,{
            duration:0.05,
            opacity: 0,
            stagger: 0.04,
            
        })
        .from("#second-slide .slide__right",
            {
                opacity:0,
                duration: .5,
            },"<"
        )
        .to("#first-slide",{
            duration: 0,
            x: - parseFloat(width) - parseFloat(width),
            opacity: 1,
        })
        positionX = parseFloat(positionX) - parseFloat(width);  
        timing = 1.5;
        index++;
    }
}
function animationCaseBack(){
    gsap.to("#second-slider",{
        duration: 1.2,
        x: parseFloat(positionSecondX) + parseFloat(width)
    });
    positionSecondX = parseFloat(positionSecondX) + parseFloat(width);
    index--;
}

function animationSlideCase(slideElement){
    var tl  = gsap.timeline({onComplete: function(){slideSplit.revert()}});
    var slideTitle  = slideElement.querySelector('.slide__title');
    var slideSplit = new SplitText(slideTitle, { type: "words,chars" });
    var slideChars = slideSplit.chars;
    var slideBckgrnd;
    var slideDuration; 
    if(width > 1024){
        slideBckgrnd = "100% 0";
        slideDuration = 3;        
    }else{
        slideBckgrnd = "75% 0";
        slideDuration = 3.5;        
    }      
        
        tl.to("#second-slider",
            {
                x:parseFloat(positionSecondX) - parseFloat(width),
                scale : 1,
                ease:"linear",
                duration: 1,
            },
        )
        .fromTo(slideElement,
            {
                backgroundPosition:'0 0',
            },
            {
                backgroundPosition:slideBckgrnd,
                duration: slideDuration,
                ease:"power1.Out"
            }
        )
        .from(slideChars,{
            duration:0.05,
            opacity: 0,
            stagger: 0.045,
            
        },"<1")
        .from(slideElement.querySelector('.slide__subtitle'),
            {
                opacity:0,
                duration: .5,
            },"<"
        )
        .from(slideElement.querySelector('.slide__right'),
            {
                opacity:0,
                duration: .5,
            },"<"
        )
        timing = 4.5;
        index++;
        positionSecondX = parseFloat(positionSecondX) - parseFloat(width);
}